import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router  } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NavController,ModalController } from '@ionic/angular';
import { LoadingController,AlertController } from '@ionic/angular';
import {Platform, NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {

  allocationid :any;
  myEntryForm: FormGroup;
  bookingno:any;
  
  
  flatambience:any;
  roomambience:any;
  roomlinen:any;
  roomamenities:any;
  washroomcondition:any;
  washroomtoiletries:any;
  foodqualities:any;
  caretaerbehaviour:any;
  securityarrangement:any;
  overallexperience:any;
  comments:any;
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private formBuilder: FormBuilder,private modalCtrl:ModalController) { 

      
    this.myEntryForm = this.formBuilder.group({
       flatambience:  ['',Validators.compose([ Validators.required])],
       roomambience:  ['',Validators.compose([ Validators.required])],
       roomlinen:  ['',Validators.compose([ Validators.required])],
       roomamenities:  ['',Validators.compose([ Validators.required])],
       washroomcondition:  ['',Validators.compose([ Validators.required])],
       washroomtoiletries:  ['',Validators.compose([ Validators.required])],
       foodqualities:  ['',Validators.compose([ Validators.required])],
       caretaerbehaviour:  ['',Validators.compose([ Validators.required])],
       securityarrangement:  ['',Validators.compose([ Validators.required])],
       overallexperience:  ['',Validators.compose([ Validators.required])],
       comments:  [''],
   
    });
    }
    closeModal()
    {
    
      this.modalCtrl.dismiss();
    }
  ngOnInit() {
    try {
      this.allocationid = this.navParams.get('allocationid'); 
      this.bookingno = this.navParams.get('bookingno'); 
    } catch (error) {
    } 
      
  
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  async doSubmit(){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Submitting ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
  
    //cns1385677
    await loading.present();
    let formObj = this.myEntryForm.getRawValue(); 
    formObj["bookingallocationid"]=this.allocationid;
    formObj["bookingno"]=this.bookingno;
    let serializedForm = JSON.stringify(formObj);
    //  console.log(serializedForm);
    await  this.authService.postDataWithAuth('/api/feedback',serializedForm).subscribe(res => {       
        try{
          //  //  console.log( JSON.stringify(res));
            if(res['status']==1){
                this.presentAlert("Success",res['message']);
                let result_data = {status: true}
               
                this.modalCtrl.dismiss(result_data);
            }else{
              this.presentAlert('Alert',res['message']);
            
            }
        
          }catch(Exce){
  
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
          this.presentAlert('Alert','Unable to submit.');
            
          //  //  console.log( JSON.stringify(err));
             
        }catch(exception){
           //  //  console.log(exception);
        }finally{          
          loading.dismiss();
        }
      });
  }

  
async  presentAlert(header,message) {   
  
  const alert = await this.alertController.create({
    header: header,
    message: message,
    buttons: ['OK']
  });
  return await alert.present();
}

}
