import { Component, OnInit } from '@angular/core';

import { NavController, ModalController } from '@ionic/angular';
import { Platform,LoadingController, AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
@Component({
  selector: 'app-booking-tracker-model',
  templateUrl: './booking-tracker-model.page.html',
  styleUrls: ['./booking-tracker-model.page.scss'],
})
export class BookingTrackerModelPage implements OnInit {
  daysList: any;
  daysObj: any;
  bookingno: any;
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams: NavParams, public alertController: AlertController, public loadingController: LoadingController,
    private modalCtrl: ModalController) {

  }

  ngOnInit() {
    this.daysList = this.navParams.get('daylist');
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  closeModal() {

    this.modalCtrl.dismiss();
  }

}
