
import { Platform, AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
const TOKEN_KEY = 'access_token';
const USER_DATA = 'user_data';
const REFRESH_KEY = 'refresh_token';

const DEFAULT_DASHBOARD = 'default_dashboard';
const USER_ROLE = 'user_roles';
import { Observable, of, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // 550006
  // 550013
  // "@angular/common": "~7.1.4",
  url____  = 'https://shapp.nrl.co.in/API_PRODUCTION';
 /// url= 'http://3.7.145.118/NRL_API_TEST';


  url_ = 'http://192.168.29.27:8080';
  url = 'http://3.7.145.118/NRL_API_TEST';

  //clsurl = 'http://3.7.145.118/NRL-TEST-API';
  // url = 'http://192.168.29.253:8080';

  //  url = 'http://192.168.29.233:8080';

  // 100315 
  // 100504
  user = null;
  authenticationState = new BehaviorSubject(false);
  Token = "";
  refreshToken = "";

  constructor(private http: HttpClient, private helper: JwtHelperService, public storage: Storage,
    private plt: Platform, private alertController: AlertController,
    private navCtrl: NavController) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    let that = this;
    this.storage.get(TOKEN_KEY).then(token => {
      if (token) {
        let decoded = that.helper.decodeToken(token);
        let isExpired = that.helper.isTokenExpired(token);
        that.Token = token;
        if (!isExpired) {
          that.user = decoded;
          that.authenticationState.next(true);
        } else {
          that.storage.remove(TOKEN_KEY);
          that.navCtrl.navigateRoot('/login');

         // this.router.navigate(['/login'], { replaceUrl: true });
        }
      } else {
        // that.navCtrl.navigateRoot('/login');
      }
    });

    this.storage.get(REFRESH_KEY).then(token => {
      if (token) {
        that.refreshToken = token;

      } else {
        that.refreshToken = null;
        that.storage.remove(TOKEN_KEY);
      }
    });


  }
  getToken() {
    let Token = "";
    let that = this;
    this.storage.get(TOKEN_KEY).then(token => {
      that.Token = token;
    });
    return Token;
  }

  postDataWithoutAuth(data, parturl) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });
    headers.set('Accept', 'application/json');
    const options = {
      headers: headers
    };
    let actionurl = this.url + parturl;
    return this.http.post(actionurl, data, options).pipe(
      catchError(e => {

        //this.showAlert(JSON.stringify(e));
        throw new Error(e);
      })
    );
  }


  postDataWithAuth(parturl, data) {

    let that = this;
    const options = {
      headers: {
        'Authorization': 'Bearer ' + this.Token,
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    console.log('Bearer ' + this.Token);
    let actionurl = this.url + parturl;
    console.log('actionurl ' + actionurl);
    return this.http.post(actionurl, data, options).pipe(
      catchError(e => {

        console.error(e);;
        if (e.status === 401) {
          // Handle 401 Unauthorized error
          that.storage.remove(TOKEN_KEY);
          this.navCtrl.navigateRoot('/login'); // Navigate to login page
        }
        throw new Error(e);
      })
    );
  }
  postDataWithAuthImage(parturl, data) {

    let that = this;
    const options = {
      headers: {
        'Authorization': 'Bearer ' + this.Token,
        'Access-Control-Allow-Origin': '*'
      }
    };
    let actionurl = this.url + parturl;
    return this.http.post(actionurl, data, options).pipe(
      catchError(e => {
        if (e.status === 401) {
          // Handle 401 Unauthorized error
          that.storage.remove(TOKEN_KEY);
          this.navCtrl.navigateRoot('/login'); // Navigate to login page
        }
        throw new Error(e);
      })
    );
  }


  getDataWithAuth(parturl) {


    let that = this;
    let actionurl = this.url + parturl;
    // let actionurl=parturl;
    //  console.log(actionurl);
    return this.http.get(actionurl,
      {
        headers: {
          'Authorization': 'Bearer ' + this.Token,
          'Access-Control-Allow-Origin': '*'
        },
        responseType: 'arraybuffer'

      }
    );
  }
  postDataWithAuthForDashbord(parturl, data) {
    try {
      this.checkToken();
    } catch (err) {

    }

    let that = this;
    const options = {
      headers: {
        'Authorization': 'Bearer ' + this.Token,
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };

    //'Access-Control-Allow-Origin': '*',
    let actionurl = this.url + parturl;
    return this.http.post(actionurl, data, options).pipe(
      catchError(e => {

        if (e.status === 401) {

          // Handle 401 Unauthorized error
          that.storage.remove(TOKEN_KEY);
          this.navCtrl.navigateRoot('/login');

          // Navigate to login page
        }
        console.error(e);
        //console.log("Dashbord Error => " + JSON.stringify(e));
        throw new Error(e);
      })
    );
  }

  login(credentials) {
    let that = this;
    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });
    headers.set('Accept', 'application/json');
    //headers.set('Access-Control-Allow-Origin', '*');
    const options = {
      headers: headers
    };
    //let actionurl = `${this.url}/api/auth/signin`;
    let actionurl = `${this.url}/api/auth/v2/signin`;
    //   this.showAlert(actionurl);

    console.log("Login Url => " + actionurl);
    return this.http.post(actionurl, credentials, options)
      .pipe(
        tap(res => {
          console.log("Login Complete => " + JSON.stringify(res));

          try {
            if (res['accessToken'] != null) {
              that.Token = res['accessToken'];
              that.refreshToken = res['refreshToken'];
              this.authenticationState.next(true);
              this.authenticationState.next(true);
              this.storage.set(REFRESH_KEY, res['refreshToken']);
              this.storage.set(TOKEN_KEY, res['accessToken']);
              this.user = this.helper.decodeToken(res['accessToken']);

              this.storage.set(USER_DATA, JSON.stringify(res['user']));
              // console.log("Login Complete => "+JSON.stringify(res['user']));
            }
          } catch (error) {

          }



        }),
        catchError(e => {
          console.error(e);
          console.log("Login Error >>" + e.message);
          //  this.showAlert("Invalid username and password"+ e.message);
          this.showAlert("We are having trouble connecting to the server. Please try again later.");
          throw new Error(e);
        })
      );
  }
  requestLoginOTP(credentials) {
    let that = this;
    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });
    headers.set('Accept', 'application/json');
    const options = {
      headers: headers
    };
    let actionurl = `${this.url}/api/auth/v2/request-login-otp`;
    //   this.showAlert(actionurl);

    console.log("Login OTP Reauest => " + actionurl);

    return this.http.post(actionurl, credentials, options).pipe(
      catchError(e => {
        this.showAlert("Unable to communicate server");
        throw new Error(e);
      })
    );

  }

  submitOTPLogin(credentials) {
    let that = this;
    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });
    headers.set('Accept', 'application/json');
    const options = {
      headers: headers
    };
    let actionurl = `${this.url}/api/auth/v2/verify-login-otp`;
    //   this.showAlert(actionurl);

    console.log("OTP Login Sunmit => " + actionurl);
    return this.http.post(actionurl, credentials, options)
      .pipe(
        tap(res => {
          //    console.log("Login Complete => " + JSON.stringify(res));

          try {
            if (res['accessToken'] != null) {
              that.Token = res['accessToken'];
              that.refreshToken = res['refreshToken'];
              this.authenticationState.next(true);
              this.authenticationState.next(true);
              this.storage.set(REFRESH_KEY, res['refreshToken']);
              this.storage.set(TOKEN_KEY, res['accessToken']);
              this.user = this.helper.decodeToken(res['accessToken']);

              this.storage.set(USER_DATA, JSON.stringify(res['user']));
              // console.log("Login Complete => "+JSON.stringify(res['user']));
            }
          } catch (error) {

          }
        }),
        catchError(e => {
          // console.error(e);
          //  this.showAlert("Unable to verify OTP");

          this.showAlert("Unable to connect to server. Please check your internet connection and try again.");
          throw new Error(e);
        })
      );
  }
  refreshAccessToken() {
    let that = this;
    try {
      const headers = new HttpHeaders({
        'Content-Type': `application/json`,
        'X-Skip-Certificate-Check': 'true'
      });
      headers.set('Accept', 'application/json');
      const options = {
        headers: headers
      };
      let formObj = {
        refreshToken: this.refreshToken
      };
      let serializedForm = JSON.stringify(formObj);
      let actionurl = `${this.url}/api/auth/refreshtoken`;
      if (!this.refreshToken) {
        return null;
      }


      return this.http.post(actionurl, serializedForm, options)
        .pipe(
          tap(res => {
            //  console.log("refreshtoken Complete => " + JSON.stringify(res));

            try {
              if (res['accessToken'] != null) {

                that.Token = res['accessToken'];
                that.refreshToken = res['refreshToken'];
                this.authenticationState.next(true);
                this.authenticationState.next(true);
                this.storage.set(REFRESH_KEY, res['refreshToken']);
                this.storage.set(TOKEN_KEY, res['accessToken']);
                this.user = this.helper.decodeToken(res['accessToken']);

                this.storage.set(USER_DATA, JSON.stringify(res['user']));
                // console.log("Login Complete => "+JSON.stringify(res['user']));
              }
            } catch (error) {
              throw new Error(error);
            }



          }),
          catchError(e => {
            // console.error(e);

            throw new Error(e);
          })
        );

    } catch (error) {
      throw new Error(error);
    }

  }

  loadDashBoardData(endurl, data): Observable<any> {

    let that = this;
    let myurl = this.url + endurl;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.Token,
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });

    //  console.log("My Token :: "+ this.Token);

    //headers.set('Content-Type', `application/json`);
    //  headers.set('Accept', 'application/json');
    //  headers.set('Authorization', 'Bearer ' +this.Token);
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');

    // headers.set('Access-Control-Allow-Headers', 'Content-Type, Authorization, X-Requested-With');
    const options = {
      headers: headers
    };


    return this.http.post(myurl, data, options).pipe(
      catchError(e => {
        this.showAlert(e.error.msg);

        // Handle 401 Unauthorized error
        that.storage.remove(TOKEN_KEY);
        this.navCtrl.navigateRoot('/login');
        throw new Error(e);
      })
    );
  }
  async logout() {
    let result = 0;
    try {
      this.storage.remove(TOKEN_KEY).then(() => {
        this.authenticationState.next(false);
        result = result + 1;
        this.storage.remove(USER_DATA);


      });
      this.storage.remove(REFRESH_KEY).then(() => {
        this.storage.remove(USER_DATA);
        result = result + 1;

      });
      await this.storage.remove(TOKEN_KEY);
      await this.storage.remove(USER_DATA);
    } catch (Exc) {

    }
    try {
      await this.storage.remove(TOKEN_KEY);
      await this.storage.remove(USER_DATA);
      this.authenticationState.next(false);
      return true;
    } catch (Exc) {
      return  false;
    }


   
  }
  logout11() {
    try {
      // Clear stored tokens from storage
      this.storage.remove(TOKEN_KEY);
      this.storage.remove(REFRESH_KEY);

      // Reset authentication state
      this.authenticationState.next(false);

      // Clear user data if necessary
      this.storage.remove(USER_DATA);

      // Optionally, reset any user-related properties
      this.user = null;
      this.Token = null;
      this.refreshToken = null;

      // Optionally, handle any post-logout actions like redirecting to login screen
      // For example: this.router.navigate(['/login']);
      console.log("User has been logged out successfully");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  getSpecialData() {
    /* //  console.log( ""+`${this.url_n}`);
     return this.http.get(`${this.url_n}`).pipe(
       catchError(e => {
         let status = e.status;
         if (status === 401) {
           this.showAlert('You are not authorized for this!');
           this.logout();
         }
         //  console.log( JSON.stringify(e));
         throw new Error(e);
       })
     );*/
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Alert',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  getUrlData(endurl) {
    let myurl = '${this.url}' + endurl;
    return this.http.get(myurl).pipe(
      catchError(e => {
        let status = e.status;
        if (status === 401) {
          this.showAlert('You are not authorized for this!');

          this.navCtrl.navigateRoot('/login');
          // this.logout();
        }
        throw new Error(e);
      })
    );
  }

  postUrlData(endurl, data) {
    let myurl = '${this.url}' + endurl;
    return this.http.post(myurl, data).pipe(
      catchError(e => {
        this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

}
