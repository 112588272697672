import { Component, OnInit } from '@angular/core';

import { NavController,ModalController } from '@ionic/angular';
import {Platform,  LoadingController,AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-booking-day-log',
  templateUrl: './booking-day-log.page.html',
  styleUrls: ['./booking-day-log.page.scss'],
})
export class BookingDayLogPage implements OnInit {
  daysList:any;
  daysObj:any;
  bookingno:any;

  isMobile: any = false;
  isIosVesrion: any = false;
  constructor(private platform: Platform,private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private modalCtrl:ModalController) { }

  ngOnInit() {
 //   this.DateWiseList = JSON.parse(this.navParams.get('guesthouses'));
    this.bookingno = this.navParams.get('bookingno');
  //  //  //  console.log(""+JSON.stringify(this.DateWiseList));
  //  //  console.log("bookingno "+ this.bookingno);
  this.initializeApp() ;
  this.fetchBookingDays(this.bookingno);
  }
  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  closeModal()
  {
  
    this.modalCtrl.dismiss();
  }
  async fetchBookingDays(bookingno){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Fetching booking details ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    let params={
        bookingno:bookingno
    };
    let serilizedObj=JSON.stringify(params);
    //  //  console.log(serilizedObj);
    await  this.authService.postDataWithAuth('/api/bookingdetails',serilizedObj).subscribe(res => {       
        try{
        
          console.log( JSON.stringify(res));
          this.daysObj= res;
           this.daysList= this.daysObj.baghlist ;
          
             console.log( JSON.stringify(this.daysList));
          }catch(Exce){
          
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
          //  //  console.log("hi");
          //  //  console.log( JSON.stringify(err));
         
        }catch(exception){
           //  //  console.log(exception);
        }finally{         
          loading.dismiss();
        }
      });
  }
 
  async  presentAlert(header,message) {
    const alertController = document.querySelector('ion-alert-controller');
    await alertController.componentOnReady();
  
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
 
  

}
