import { Component, OnInit } from '@angular/core';

import {Platform, NavController,ModalController } from '@ionic/angular';
@Component({
  selector: 'app-check-in-date-selector',
  templateUrl: './check-in-date-selector.page.html',
  styleUrls: ['./check-in-date-selector.page.scss'],
})
export class CheckInDateSelectorPage implements OnInit {
  isCheckInSelected:boolean=true;
  CheckInDateText:any;
  CheckOutDateText:any;
  CheckInDate;
  CheckOutDate;

  CheckInMinDate;
  CheckInMaxDate;

  CheckOutMinDate;
  CheckOutMaxDate;
  isValidDate=true;


  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private modalCtrl:ModalController) {
    this.CheckInMinDate= new Date(); 
    this.CheckInMaxDate= new Date(); 
    this.CheckInMinDate.setTime( this.CheckInMinDate.getTime() -(24*60*60*1000)); 
    this.CheckInMaxDate.setTime( this.CheckInMaxDate.getTime() +45*(24*60*60*1000)); 
    


    this.CheckInDate= new Date(); 
    this.CheckOutDate= new Date(); //this.formatDate(this.curDate);

    this.CheckOutDate.setTime( this.CheckOutDate.getTime() +(24*60*60*1000)); 

    this.CheckInDateText=this.formatDate(this.CheckInDate);
    this.CheckOutDateText=this.formatDate(this.CheckOutDate);
    
    this.CheckOutMinDate= new Date(); 
    this.CheckOutMaxDate= new Date(); 
    this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 
    this.CheckOutMaxDate.setTime( this.CheckOutMinDate.getTime() +15*(24*60*60*1000));
   }


  ngOnInit() {
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }
  selectCheckInDate(){
    this.isCheckInSelected=true;
  }
  
  selectCheckOutDate(){
   // this.CheckOutMinDate= new Date(); 
  //  this.CheckOutMaxDate= new Date(); 
   // this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 
  //  this.CheckOutMaxDate.setTime( this.CheckOutMinDate.getTime() +30*(24*60*60*1000));
    this.isCheckInSelected=false;
  }
  CheckInDateSelected(date){
    /*
    this.CheckInDate= new Date(date);
    this.CheckInDateText=this.formatDate(date);
    this.CheckOutMinDate= new Date(); 
    if(this.CheckOutDate<=this.CheckInDate){ 
      this.CheckOutDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
      this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 

      this.CheckOutMaxDate.setTime( this.CheckInDate.getTime() +(30*24*60*60*1000));
      
      this.CheckOutDateText=this.formatDate( this.CheckOutDate);

    }else{

      this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 
      this.CheckOutMaxDate.setTime( this.CheckInDate.getTime() +30*(24*60*60*1000));
    }*/

   /// if(this.CheckOutDate<=this.CheckInDate){ 

      this.CheckInDate= new Date(date);
      this.CheckInDateText=this.formatDate(date);
      this.CheckOutMinDate= new Date(date); 
      this.CheckOutMaxDate= new Date(date); 
      this.CheckOutDate= new Date(date); 
      
      this.CheckOutMaxDate.setDate( this.CheckInDate.getDate() +15);
      this.CheckOutDate.setDate( this.CheckInDate.getDate() +1); 
      this.CheckOutMinDate.setDate( this.CheckInDate.getDate() +1); 
/*
      this.CheckOutMaxDate.setTime( this.CheckInDate.getTime() +(30*24*60*60*1000));
      this.CheckOutDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
      this.CheckOutMinDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); */
      
      this.CheckOutDateText=this.formatDate( this.CheckOutDate);
      //  //  console.log("Phase 1 ");
  /*  }else{
      //  //  console.log("Phase 2 ");
      this.CheckInDate= new Date(date);

    
      this.CheckInDateText=this.formatDate(date);
     
      this.CheckOutMinDate= new Date(date); 
      this.CheckOutMaxDate= new Date(date); 
      this.CheckOutDate= new Date(date); 

      this.CheckOutMaxDate.setTime( this.CheckInDate.getTime() +(30*24*60*60*1000));
      this.CheckOutDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000));  
      this.CheckOutMinDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
      this.CheckOutDateText=this.formatDate( this.CheckOutDate);


     // this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 
     // this.CheckOutMaxDate.setTime( this.CheckOutMinDate.getTime() +(24*60*60*1000)); 
    }*/
    
    let TotalDays=this.getDays(this.CheckOutDate,this.CheckInDate);
    if(TotalDays>0){
      this.isValidDate=true;
    }else{
      this.isValidDate=false;
    }
    this.selectCheckOutDate( );
  }
  CheckOutDateSelected(date){
    this.CheckOutDate= new Date(date);
    this.CheckOutDateText=this.formatDate(date);
    let TotalDays=this.getDays(this.CheckOutDate,this.CheckInDate);
    if(TotalDays>0){
      this.isValidDate=true;
    }else{
      this.isValidDate=false;
    }
    
  }

  formatDate(date) {
    let d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }


  onSubmit(){
    let TotalDays=this.getDays(this.CheckOutDate,this.CheckInDate);
    //  //  console.log("TotalDays "+TotalDays);
    if(TotalDays>0){
      let check_in_data = {status: true,check_in_date: this.CheckInDate, check_out_date: this.CheckOutDate};
      this.modalCtrl.dismiss(check_in_data);
    }else{
      this.isValidDate=false;
      //  //  console.log("In Valid Date :: TotalDays : "+TotalDays);
    }


   
  }

  getDays(fromdate,todate){
    // let returnVal=true;
    let dFromdate=new Date(fromdate);
    let dTodate=new Date(todate);
    let difference = dFromdate.getTime() - dTodate.getTime() ;

     return difference / (24 * 3600 * 1000);
  }
}
