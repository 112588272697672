import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router  } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NavController,ModalController } from '@ionic/angular';
import {Platform, LoadingController,AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-new-device-add',
  templateUrl: './new-device-add.page.html',
  styleUrls: ['./new-device-add.page.scss'],
})
export class NewDeviceAddPage implements OnInit {
  Ghid :any;
  myEntryForm: FormGroup;
  deviceaddress:any;
  devicename:any;
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private formBuilder: FormBuilder,private modalCtrl:ModalController) { 

      
    this.myEntryForm = this.formBuilder.group({
      deviceaddress :  ['',Validators.compose([Validators.maxLength(60),Validators.minLength(5), Validators.required])],
      devicetype :  ['',Validators.compose([Validators.maxLength(20),Validators.minLength(3), Validators.required])],
      devicename :  ['',Validators.compose([Validators.maxLength(20),Validators.minLength(3), Validators.required])]
     
   
    });
    }
    closeModal()
    {
    
      this.modalCtrl.dismiss();
    }
  ngOnInit() {
    try {
      this.Ghid = this.navParams.get('DefaultGuestHouseId'); 
    } catch (error) {
      
    }
   
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  async doSubmit(){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Updating...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    let formObj = this.myEntryForm.getRawValue(); 
    formObj["ghid"]=this.Ghid;

    let serializedForm = JSON.stringify(formObj);
    //  console.log(serializedForm);
    await  this.authService.postDataWithAuth('/api/addnewdevice',serializedForm).subscribe(res => {       
        try{
          //  console.log( JSON.stringify(res));
          if(res['status']==1){
            this.presentAlert("Success",res['message'])
            let device_data = {status: true};
            this.modalCtrl.dismiss(device_data);
          }else{
            this.presentAlert('Error','Unable to update.');
          
          }
        
          }catch(Exce){
  
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
         
          //  console.log( JSON.stringify(err));
             
        }catch(exception){
           //  console.log(exception);
        }finally{          
          loading.dismiss();
        }
      });


   
  }
  async  presentAlert(header,message) {
    const alertController = document.querySelector('ion-alert-controller');
    await alertController.componentOnReady();
  
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
 

}
