import { Component, OnInit } from '@angular/core';
import { Platform, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.page.html',
  styleUrls: ['./app-update.page.scss'],
})
export class AppUpdatePage implements OnInit {
  pageTitle="";
  type=-9;
  message="";
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams:NavParams,) {
    this.pageTitle = this.navParams.get('heading');
    this.type = this.navParams.get('type');
    this.message = this.navParams.get('message');
    
   }

  ngOnInit() {
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  doUpdate(){
    window.open("https://play.google.com/store/apps/details?id=com.bspl.nrlgh", "_system", "location=yes");
  }
}
