import { Component, OnInit } from '@angular/core';

import { NavController,ModalController } from '@ionic/angular';
import { LoadingController,AlertController } from '@ionic/angular';
import {Platform, NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-forwarded-hotel-list',
  templateUrl: './forwarded-hotel-list.page.html',
  styleUrls: ['./forwarded-hotel-list.page.scss'],
})
export class ForwardedHotelListPage implements OnInit {
  GuestHouseList:any;
  HotelList:any;
  bookingno:any;
  DefaultGuestHouseId:any;
  daysList:any;
  bookingallocationid:any;
  FinalList=new Array(); 
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private modalCtrl:ModalController) {
      
     }

  ngOnInit() {
    //  //  console.log("##################################");
    
    try {
      this.GuestHouseList = JSON.parse(this.navParams.get('guesthouses'));
      this.HotelList = JSON.parse(this.navParams.get('hotels'));
      this.daysList = JSON.parse(this.navParams.get('daysList'));
      this.bookingno = this.navParams.get('bookingno');
      this.bookingallocationid= this.navParams.get('bookingallocationid');
      this.DefaultGuestHouseId = this.navParams.get('DefaultGuestHouseId');
      //  //  console.log("Days:: "+JSON.stringify(this.daysList));
    } catch (error) {
      //  //  console.log("Days "+JSON.stringify(error));
    }
  
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }

  onSelectGuestHouse(name,ghid,HotelId,type){
    this.submitForwardReason(name,ghid,HotelId,type);
    this.FinalList=new Array(); 
    for (let item of this.daysList){
      this.FinalList.push(item.bookingdate);
    }
   // let guest_house_data = {status: true,name: name, code: code};
   // this.modalCtrl.dismiss(guest_house_data);
  }
  async submitForwardReason(name,ghid,HotelId,type) {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Want to forward this booking request to '+type+'<br><b>'+name +' </b>',
      inputs: [
        {
          name: 'remark',
          type: 'text',
          label:'Enter forward remark',
          placeholder: 'Type your remark(s)'
        }
      
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //  //  console.log('Confirm Cancel');
          }
        }, {
          text: 'Forward',
          handler: (data) => {
            var  remarks=data.remark; 
            //  //  console.log('Confirm Ok : '+remarks);
          
           
            if (remarks.length  <5) {
              alert.message = "<span style='color:#f45c2d;'>Please provide a valid remark</span>" ;
                return false;
            } else {
              this.submitForwardRequest(this.bookingno,ghid,HotelId,remarks,type)
            }
          }
        }
      ]
    });

    await alert.present();
  }
  async submitForwardRequest(bookingno,GuestHouseId,HotelId,remark,type){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Updating ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });

    //cns1385677
    await loading.present();
    const formObj = {
      bookingno: bookingno,
      ghid: GuestHouseId,
      hotelid:HotelId,
      bookingallocationid:this.bookingallocationid,
      allocationdates:  this.FinalList,
      forwardedremarks: remark,
      type:type
    };
    let serializedForm = JSON.stringify(formObj);
    //  //  console.log(serializedForm);
    await  this.authService.postDataWithAuth('/api/forwardbooking',serializedForm).subscribe(res => {       
        try{
          //  //  console.log( JSON.stringify(res));
            if(res['status']==1){
                this.presentAlert("Success",res['message']);
                 let guest_house_data = {status: true};
                  this.modalCtrl.dismiss(guest_house_data);
            }else{
              this.presentAlert('Alert',res['message']);
            
            }
        
          }catch(Exce){
  
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
          this.presentAlert('Alert','Unable to forward.');
            
          //  //  console.log( JSON.stringify(err));
             
        }catch(exception){
           //  //  console.log(exception);
        }finally{          
          loading.dismiss();
        }
      });
  }
  
  async  presentAlert(header,message) {
    const alertController = document.querySelector('ion-alert-controller');
    await alertController.componentOnReady();
  
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
  isValid(ghtype,ghid){
    let returnVal=true;
      
      if(ghid==this.DefaultGuestHouseId ){
        returnVal=false;
      }

      return returnVal;
    
  }
  

}
