import { Component, OnInit } from '@angular/core';

import {Platform, NavController,ModalController } from '@ionic/angular';
@Component({
  selector: 'app-date-range-inclusive',
  templateUrl: './date-range-inclusive.page.html',
  styleUrls: ['./date-range-inclusive.page.scss'],
})
export class DateRangeInclusivePage implements OnInit {
  isCheckInSelected:boolean=true;
  CheckInDateText:any;
  CheckOutDateText:any;
  CheckInDate;
  CheckOutDate;


  CheckInMinDate;
  CheckInMaxDate;

  CheckOutMinDate;
  CheckOutMaxDate;
  isValidDate=true;

  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private modalCtrl:ModalController) {
    this.CheckInMinDate= new Date(); 
    this.CheckInMaxDate= new Date(); 
    this.CheckInMinDate.setTime( this.CheckInMinDate.getTime() -(24*60*60*1000)); 
    
   // this.CheckInMinDate.setTime( this.CheckInMinDate.getTime() ); 
    //this.CheckInMaxDate.setTime( this.CheckInMaxDate.getTime() ); 
    


    this.CheckInDate= new Date(); 
    
    //this.CheckInDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
    
    this.CheckOutDate= new Date(); //this.formatDate(this.curDate);

   // this.CheckOutDate.setTime( this.CheckOutDate.getTime() +(24*60*60*1000)); 

    this.CheckInDateText=this.formatDate(this.CheckInDate);
    this.CheckOutDateText=this.formatDate(this.CheckOutDate);
    
    this.CheckOutMinDate= new Date(); 
    this.CheckOutMinDate.setDate( this.CheckInDate.getDate() ); 
   }


  ngOnInit() {
    this.initializeApp();
  }

  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isMobile = true;
          this.isIosVesrion = true;
        } else if (this.platform.is("android")) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    } catch (error) {
    }
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }
  selectCheckInDate(){
    this.isCheckInSelected=true;
  }
  
  selectCheckOutDate(){
    this.isCheckInSelected=false;
  }
  CheckInDateSelected(date){
 

    this.CheckInDate= new Date(date);
    this.CheckInDateText=this.formatDate(date);

    this.CheckOutDate= new Date(date); 
    
  //  this.CheckOutMaxDate.setDate( this.CheckInDate.getDate() +30);
   
    this.CheckOutDateText=this.formatDate( this.CheckOutDate);
    this.isValidDate=true;

   this.selectCheckOutDate();
  }
  CheckOutDateSelected(date){
    this.CheckOutDate= new Date(date);
    this.CheckOutDateText=this.formatDate(date);
    let TotalDays=this.getDays(this.CheckInDate,this.CheckOutDate);
    if(TotalDays>0){
      this.isValidDate=true;
    }else {
      this.isValidDate=true;
    }
    
  }

  formatDate(date) {
    let d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }


  onSubmit(){
    let TotalDays=this.getDays(this.CheckInDate,this.CheckOutDate);
     console.log("TotalDays "+TotalDays);
   // if(TotalDays>0){
      let check_in_data = {status: true,from_date: this.CheckInDate, to_date: this.CheckOutDate};
      this.modalCtrl.dismiss(check_in_data);
   // }else{
      this.isValidDate=false;
   //   //  //  //  console.log("In Valid Date :: TotalDays : "+TotalDays);
  //  }


   
  }

  getDays(fromdate,todate){
    // let returnVal=true;
    let dFromdate=new Date(fromdate);
    let dTodate=new Date(todate);
    let difference =  dTodate.getTime() -dFromdate.getTime();

     return difference / (24 * 3600 * 1000);
  }
}