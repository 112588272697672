 //import { NgModule } from '@angular/core';
 import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SSLInterceptor } from './ssl-interceptor';
//import { CustomHttpXhrBackend } from './custom-http-backend'; 

import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
 

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GuestHouseSelectorPageModule } from './model/guest-house-selector/guest-house-selector.module';
import { ForwardedHotelListPageModule } from './model/forwarded-hotel-list/forwarded-hotel-list.module';
import { CheckInDateSelectorPageModule } from './model/check-in-date-selector/check-in-date-selector.module';
import { GuestHouseAssignPageModule } from './model/guest-house-assign/guest-house-assign.module';

import { ViewIdentityImagePageModule } from './pages/common/view-identity-image/view-identity-image.module';

import { VacancyDateRangeSelectorPageModule } from './model/vacancy-date-range-selector/vacancy-date-range-selector.module';

import { DateRangeSelectorPageModule } from './model/date-range-selector/date-range-selector.module';
import { DateRangeInclusivePageModule } from './model/date-range-inclusive/date-range-inclusive.module';
import { EmployeeSearchPageModule } from './model/employee-search/employee-search.module';
import { NonNrlEmpoloyeeSearchPageModule } from './model/non-nrl-empoloyee-search/non-nrl-empoloyee-search.module';

import { CareTakerRoomAllocationPageModule } from './model/care-taker-room-allocation/care-taker-room-allocation.module';
import { ReallocationAfterCheckedInPageModule } from './model/reallocation-after-checked-in/reallocation-after-checked-in.module';

import { DateSelectorPageModule } from './model/date-selector/date-selector.module';
import { BookingDayLogPageModule } from './model/booking-day-log/booking-day-log.module';
import { NewDeviceAddPageModule } from './model/new-device-add/new-device-add.module';
import { SelectBlockedRoomsPageModule } from './model/select-blocked-rooms/select-blocked-rooms.module';

import { ApproveBookingModelPageModule } from './model/approve-booking-model/approve-booking-model.module';

import { StartUpPagePageModule } from './pages/employee/start-up-page/start-up-page.module';
import { HelpPageModule } from './pages/common/help/help.module';

import { AppUpdatePageModule } from './model/app-update/app-update.module';

import { FeedbackPageModule } from './model/feedback/feedback.module';
//import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/v4";
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/v4";

import { FCM } from '@awesome-cordova-plugins/fcm/ngx';

//import { FCM } from "@capacitor-community/fcm";
//import { PushNotifications } from "@capacitor/push-notifications";

//import { FCM } from '@ionic-native/fcm/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Storage, IonicStorageModule } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
//import { DatePickerModule } from 'ionic-calendar-date-picker';
import { DatePickerModule } from 'ionic4-date-picker';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Base64 } from '@ionic-native/base64/ngx';
import { IonicRatingModule } from 'ionic4-rating';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { BookingTrackerModelPageModule } from './model/booking-tracker-model/booking-tracker-model.module';

import { ServiceWorkerModule } from '@angular/service-worker';

//import { CalendarModule } from 'ion2-calendar';


export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('access_token');
    },
    whitelistedDomains: ['localhost:5000']
  }
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }), AppRoutingModule,
    HttpClientModule,GuestHouseSelectorPageModule,
    ForwardedHotelListPageModule,
    CheckInDateSelectorPageModule,
    GuestHouseAssignPageModule,
    VacancyDateRangeSelectorPageModule,
    DateRangeSelectorPageModule,
    DateRangeInclusivePageModule,
    DateSelectorPageModule,
    BookingDayLogPageModule,
    NewDeviceAddPageModule,
    CareTakerRoomAllocationPageModule,
    ReallocationAfterCheckedInPageModule,
    SelectBlockedRoomsPageModule,
    StartUpPagePageModule,
    ViewIdentityImagePageModule,
    FeedbackPageModule,
    EmployeeSearchPageModule,
    NonNrlEmpoloyeeSearchPageModule,
    ApproveBookingModelPageModule,
    AppUpdatePageModule,
    IonicRatingModule,
    HelpPageModule,
    BookingTrackerModelPageModule
  ,JwtModule.forRoot({
    jwtOptionsProvider: {
      provide: JWT_OPTIONS,
      useFactory: jwtOptionsFactory,
      deps: [Storage],
    }
  }),
  DatePickerModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: true,
    // other options
  }),
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SSLInterceptor,
      multi: true,
    },
   { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      Camera,
    File,
    WebView,
    FilePath,
    Geolocation,
    Base64,
    AndroidPermissions,
    SQLite,
    InAppBrowser,FCM
  ],
  bootstrap: [AppComponent],
  exports: [
 ]
})
export class AppModule {}
