import { Component, OnInit } from '@angular/core';

import {Platform, NavController,ModalController } from '@ionic/angular';
import { LoadingController,AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-care-taker-room-allocation',
  templateUrl: './care-taker-room-allocation.page.html',
  styleUrls: ['./care-taker-room-allocation.page.scss'],
})
export class CareTakerRoomAllocationPage implements OnInit {
 
  GuestHouseList:any;
  DefaultGuestHouseName:any;
  DefaultGuestHouseId:any="";
  isValidDate=true;
  RoomsAvailable:any;
  haveData=false;
  myFromDate:any;
  myToDate:any;
  mySingleDate:any;
  messageText="NRL Guest House";
  
  
  SingleDateDisplay;

  FromDateDisplay;
  ToDateDisplay;
  hide:false;
  FinalRoomList=[];


  SelectedRoomList=[];
  OriginalRoomList=[];
  MyFinalRoomList=[];
  start=0;
  end=7;
  maxsize=7;
  bookingno;
  dataArrayObj:any=[];
  SelectedRoomListString:any="";
  bookingallocationid:any;

  noofDays=0;
  isGHIC=false;
  isIosVesrion: boolean = false;
  isMobile: boolean = false;
  constructor(private platform: Platform,private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private modalCtrl:ModalController) { 
  
     
      this.FromDateDisplay= new Date();
      this.FromDateDisplay.setDate( this.FromDateDisplay.getDate() -1); 
      this.ToDateDisplay= new Date();
      this.ToDateDisplay.setDate( this.ToDateDisplay.getDate() ); 
    
      this.dataRedesign();
}
ngOnInit() {
   //   this.DefaultGuestHouseId =  this.navParams.get('DefaultGuestHouseId');
     this.DefaultGuestHouseName =  this.navParams.get('ghname');
     this.bookingallocationid = this.navParams.get('bookingallocationid'); 
     this.bookingno=  this.navParams.get('bookingno'); 
     try{
      this.isGHIC=  this.navParams.get('isGHIC');
     }catch(E){}
     

     this.getAvailibility( this.bookingallocationid);
     this.initializeApp();
    }
  
    async initializeApp() {
      try {
        this.platform.ready().then(() => {
          if (this.platform.is("ios")) {
            this.isMobile = true;
            this.isIosVesrion = true;
          } else if (this.platform.is("android")) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
        });
      } catch (error) {
      }
    }
closeModal(){  
    this.modalCtrl.dismiss();
}
async onItemClick1(div) {  
  let remarks="Room is currently block.<br><br>\nAre you want to continue?";
  try{
    if(div.remarks){
      remarks="<span style='color:#000'>Room is currently block for </span><span style='color:red;font-weight: bold;'>"+div.remarks+"</span><span style='color:#000'>.<br><br>\nAre you want to continue?</span>";
    }
  }catch(d){}

  if(this.isGHIC){

    const alert = await this.alertController.create({
      header: "Block Status",
      message:remarks,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //  //  console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.onItemClick(div);
          }
        }
      ]
    });

    await alert.present();



    
  }
}
onItemClick2(div) {  
  if(this.CheckIsWaitingDay(div)){
    this.onItemClick(div);
  }
}
CheckIsWaitingDay(room){
  let isExist=false;
  
   for (let item of this.OriginalRoomList){
     if(item.allocationdate==room.allocationdate){
         isExist=true;  
     }
    
   }
  
   return isExist;
}

onItemClick(div) {  
  if (div.isClicked) {
   // this.RemoveFromSelectedList(div);
   // div.isClicked = false;
  } else {

    if(this.checkExistanceStatus(div)==false){
      div.isClicked = true;
    }
  
  }
  this.SelectedRoomListString="";
  for (let room of this.SelectedRoomList){
    if(this.SelectedRoomListString===""){
      this.SelectedRoomListString= "R-"+room.roomno+"("+this.formatDateShort(room.allocationdate)+")";
    }else{
      this.SelectedRoomListString=""+this.SelectedRoomListString+",R-"+room.roomno+"("+this.formatDateShort(room.allocationdate)+")";
    }
    
  }
}
PreviousPage(){
  if(this.start>0){
    this.start=this.start-7;
    this.end=this.end-7;
  }
}
NextPage(){
  //  //  console.log("maxsize ::" + this.maxsize+" endsize ::" + this.end);
  if(this.maxsize-1 > this.end){
    this.start=this.start+7;
    this.end=this.end+7;
  }

}
 RemoveFromSelectedList(room){
  let i=0;
  for (let item of this.SelectedRoomList){
    if(item.ghroomid==room.ghroomid){
      this.SelectedRoomList.splice(i, 1);      
    }
    i++;
  }
  
}
swipeEvent(event){
  //  //  console.log('swiped '+JSON.stringify(event) );
  //  //  console.log('swiped direction '+event.direction );
   
}

checkExistanceStatus(room){
  let isExist=false;
 // //  //  console.log("allocationdate  :: " +room.allocationdate);
  let i=0;
  for (let item of this.SelectedRoomList){
  //  //  //  console.log("SelectedRoomList  :: " +item.allocationdate);
    if(item.allocationdate==room.allocationdate){
      item.isClicked = false;
      this.SelectedRoomList.splice(i, 1);    
    }
    i++;
  }
  if(!isExist){
    this.SelectedRoomList.push(room);
  }
  return isExist;
}


dataRedesign(){
  let RoomList=[];
  let MinDate:Date=null;
  let MaxDate:Date=null;
  for (let item of this.dataArrayObj){ 
    let isExist=false;
    for (let room of RoomList){
        if(item.ghroomid==room.ghroomid){
          isExist=true;
        }
    }
    if(!isExist){
      RoomList.push(item);
    }    
  }
 this.FinalRoomList=[];
  for (let room of RoomList){ 
    let TempList=[];
    for (let item of this.dataArrayObj){
      if(item.ghroomid==room.ghroomid){
        if(item.finalstatus=="ALLOCATED" && item.bookingno==this.bookingno){
          item['isClicked']=true;
          this.SelectedRoomList.push(item);
          this.OriginalRoomList.push(item);
        }else{
          item['isClicked']=false;
        }
        
        TempList.push(item);
      }  

    }
    this.noofDays= TempList.length;
    TempList.sort(function(a,b){
      let first=b.allocationdate;
      let second=a.allocationdate;
      let firstDate=new Date(first);
      let secondDate=new Date(second);
      return secondDate.getTime()-firstDate.getTime()  ;
    });
    
    if(MinDate==null){
      MinDate=new Date(TempList[0].allocationdate);
    }
    if(MaxDate==null){
      MaxDate=new Date(TempList[TempList.length-1].allocationdate);
    }
    //  //  console.log("MinDate :: "+MinDate);
    
    if(MinDate.getDay()!=1){
      let TempDate:Date=new Date();
      TempDate.setTime(MinDate.getTime());
      do{        
        TempDate.setTime(TempDate.getTime() -(24*60*60*1000)); 
        //  //  console.log("Current Day :: "+TempDate.toDateString() +" "+TempDate.getTime());
        let obj={
          allocationdate:TempDate.getTime(),
          finalstatus:'NA',
          isClicked:false
        } ;
        
        TempList.push(obj);
        //  //  console.log("Current Day :: "+JSON.stringify( TempList));
      }while(TempDate.getDay()!=1)

    }

    if(MaxDate.getDay()!=0){
      let TempDate:Date=new Date();
      TempDate.setTime(MaxDate.getTime());
      while(TempDate.getDay()!=0){        
        TempDate.setTime(TempDate.getTime() +(24*60*60*1000)); 
        //  //  console.log("Current Day :: "+TempDate.toDateString());
        let obj={
          allocationdate:TempDate.getTime(),
          finalstatus:'NA',
          isClicked:false
        } ;        
        TempList.push(obj);
      }
    }
    
    TempList.sort(function(a,b){
      let first=b.allocationdate;
      let second=a.allocationdate;
      let firstDate=new Date(first);
      let secondDate=new Date(second);
      return secondDate.getTime()-firstDate.getTime()  ;
    });
   
    //  //  console.log("Current Day :: "+MinDate.getDay());    
    let roomInfo={
      ghroomid:room.ghroomid,
      roomno:room.roomno,
      ghid:room.ghid,
      ghcode:room.ghcode,
      datewiseavailability:TempList

    };
    this.maxsize=TempList.length;
    this.FinalRoomList.push(roomInfo);
  }
  ////  //  console.log("FinalRoomList :: "+JSON.stringify(  this.FinalRoomList));
 // let reminder=this.maxsize%7;
  //this.maxsize=this.maxsize+reminder;
}
onSubmit(){
  this.MyFinalRoomList=[];
  for (let item of this.SelectedRoomList){
      let mObj={
        allocationid:item.allocationid,
        allocationdate:item.allocationdate,
        ghroomid:item.ghroomid,
        roomno:item.roomno,
        ghid:item.ghid,
        ghcode:item.ghcode,
        finalstatus:item.finalstatus,
        bookingallocationid:this.bookingallocationid
      };


      this.MyFinalRoomList.push(mObj);

  }

  //let result_data = {status: true,rooms: this.MyFinalRoomList};
  ////  //  console.log(JSON.stringify(result_data));
 // this.modalCtrl.dismiss(result_data);

 //  //  console.log("Nof of days :" +this.noofDays);
 //  //  console.log("Days Applied :" + this.MyFinalRoomList.length);
 if(this.noofDays== this.MyFinalRoomList.length){
  this.submitReallocation(this.bookingallocationid,this.MyFinalRoomList);
 }else{
 
   if(this.isGHIC){
    this.confirmReallocate(this.noofDays, this.MyFinalRoomList.length);
   }else{
    this.presentAlert("Alert","Please select all possible days");
   }
 }


}

async confirmReallocate(noofDays,alocationdays){
  const myalert = await this.alertController.create({
    header: 'Alert ',
    subHeader:'No of booking days and alloaction days mismatch ',
    message:'No of booking days: '+noofDays +'\nNo of alloaction days: '+alocationdays,
    
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          //  //  console.log('Confirm Cancel');
        }
      }, {
        text: 'Submit',
        handler: () => {
          this.submitReallocation(this.bookingallocationid,this.MyFinalRoomList);
        }
      }
    ]
  });

  await myalert.present();
}

async getAvailibility(bookingallocationid){
  const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Loading ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    this.DefaultGuestHouseId=4;
  await  loading.present();
  const frmObj={
    bookingallocationid:bookingallocationid
  };
  this.RoomsAvailable=[];
 let serializedData=JSON.stringify(frmObj);
 //  //  console.log("serializedData "+serializedData);
  await  this.authService.postDataWithAuth('/api/viewroomstoreallocate',serializedData).subscribe(res => {       
      try{
     // console.log( JSON.stringify(res));
        this.RoomsAvailable=res;
        this.dataArrayObj=res;
        if(this.RoomsAvailable.length>0){
          this.haveData=true;
          this.dataRedesign();
        }else{
          this.haveData=false;
          this.messageText="No Data AVailable";
        }
       
        }catch(Exce){
          this.messageText="No Data AVailable";
        }finally{          
          loading.dismiss();
       }
    }, (err) => {
      try{
       
        //  //  console.log( JSON.stringify(err));
           
      }catch(exception){
         //  //  console.log(exception);
      }finally{
        this.messageText="No Data AVailable";          
        loading.dismiss();
      }
    });
}
formatDate(date) {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}

formatDateShort(date) {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month ].join('-');
}


getDays(fromdate,todate){
  // let returnVal=true;
  let dFromdate=new Date(fromdate);
  let dTodate=new Date(todate);
  let difference = dFromdate.getTime() - dTodate.getTime() ;

   return difference / (24 * 3600 * 1000);
}
getDays1(fromdate,todate){
  // let returnVal=true;
  let dFromdate=new Date(fromdate);
  let dTodate=new Date(todate);
  let difference = dTodate.getTime()- dFromdate.getTime()  ;

   return difference / (24 * 3600 * 1000);
}

async  presentAlert(header,message) {
   
  
  const alert = await this.alertController.create({
    header: header,
    message: message,
    buttons: ['OK']
  });
  return await alert.present();
}

async submitReallocation(bookingallocationid,roomlist){
  const loading = await this.loadingController.create({
    animated:true,
    spinner:"bubbles",
    message: 'Reallocating ...',
    translucent: true,
    cssClass: 'custom-class custom-loading'
  });

  //cns1385677
  await loading.present();
  const formObj = {
    bookingallocationid:bookingallocationid,
    roomallocationlist: roomlist
  };
  let serializedForm = JSON.stringify(formObj);
  //  //  console.log(serializedForm);
  await  this.authService.postDataWithAuth('/api/reallocaterooms',serializedForm).subscribe(res => {       
      try{
        //  //  console.log( JSON.stringify(res));
          if(res['status']==1){
              this.presentAlert("Success",res['message'])
              let result_data = {status: true,rooms: this.MyFinalRoomList}
             
              this.modalCtrl.dismiss(result_data);
          }else{
            this.presentAlert('Alert',res['message'])
          
          }
      
        }catch(Exce){

        }finally{          
          loading.dismiss();
       }
    }, (err) => {
      try{
        this.presentAlert('Alert','Unable to reallocate.');
          
        //  //  console.log( JSON.stringify(err));
           
      }catch(exception){
         //  //  console.log(exception);
      }finally{          
        loading.dismiss();
      }
    });
}

}
