import { Component, ViewChildren, QueryList } from '@angular/core';

import { Platform, ModalController, MenuController, ActionSheetController } from '@ionic/angular';
import { PopoverController, ToastController, IonRouterOutlet, NavController } from '@ionic/angular';

import { LoadingController, AlertController } from '@ionic/angular';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
//import { FCM } from '@ionic-native/fcm/ngx';
//import { FCM } from "@capacitor-community/fcm";
//import { PushNotifications } from "@capacitor/push-notifications";
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/v4";
import { AuthService } from './services/auth.service';
import { DbTaskService } from './services/db-task.service';
import { Storage } from '@ionic/storage';

import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { SwUpdate } from '@angular/service-worker';


//import { FCM } from '@capacitor-community/fcm';


const USER_DATA = 'user_data';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  myDate: string;
  isWebVersion = false;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  navLinksArray = [];
  constructor(
    private fcm: FCM,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public modalCtrl: ModalController,
    private menu: MenuController,
    private authService: AuthService,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private router: Router,

    private route: ActivatedRoute,
    private toastCtrl: ToastController,
    private sqlite: SQLite,
    public navCtrl: NavController, private storage: Storage,
    public dbTaskService: DbTaskService,
    public loadingController: LoadingController,
    private swUpdate: SwUpdate
  ) {
    try {

      this.splashScreen.hide();
    } catch (error) {

    }
    try {
      this.initializeApp();
    } catch (error) {

    }

  }
  ngOnInit() {
    // Ensure the splash screen is hidden when the web app is loaded
    

  }
  async fcmSetup() {
    try {

      // "@angular/compiler-cli": "~7.1.4",
      // await PushNotifications.requestPermissions();

      // // Register with the push notification service
      // PushNotifications.register();

      // // On successful registration, get the device token
      // PushNotifications.addListener('registration', (token: Token) => {
      //   console.log('Push registration success, token: ', token.value);
      //   // Save the token or send it to your server
      // });

      // // On registration error
      // PushNotifications.addListener('registrationError', (error: any) => {
      //   console.error('Push registration error: ', error);
      // });

      // // Handle incoming push notifications
      // PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
      //   console.log('Push notification received: ', notification);
      //   // Handle the push notification
      // });

      // // Handle when a push notification is opened
      // PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => {
      //   console.log('Push notification action performed: ', notification);
      //   // Handle the action performed
      // });

    } catch (error) {

    }
    try {
      this.fcm.getToken().then(token => {
      });
      this.fcm.subscribeToTopic("all");
      this.fcm.subscribeToTopic("testing");
    } catch (error) {
    }
    try {
      this.fcm.onNotification().subscribe(data => {
        if (data.wasTapped) {
          console.log("Received in background");
        } else {
          console.log("Received in foreground");
        };
      });
    } catch (fff) {

    }


  }

  async initializeApp() {
    try {

 
      this.platform.ready().then(() => {
       // alert("error 98s "  );
        try {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
          this.checkForUpdates();

        this.backButtonEvent();
        } catch (error) {
          //alert("error 99 "  );
        }

        //  this.fcmSetup();
        // this.router.navigate(['home-screen']);
        // this.doAuthorizationApp();
      //  alert("isWebVersionww " + this.isWebVersion);
        if (this.platform.is("ios")) {
          this.isWebVersion = false;

        } else if (this.platform.is("android")) {
          this.isWebVersion = false;
        } else {
          this.isWebVersion = true;
        }

        if (this.isWebVersion) {
          this.doAuthorizationApp();
        } else {

        //  alert("isWebVersion" + this.isWebVersion);
          this.doRefreshToken();
        }

      });
    } catch (error) {
    //  alert("fgdfg" );
    }


  }


  async doRefreshToken() {
    let that = this;
    const loading = await this.loadingController.create({
      animated: true,
      spinner: "bubbles",
      message: 'Loading...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    try {

      this.authService.refreshAccessToken().subscribe(state => {

        that.doAuthorizationApp();
        loading.dismiss();
      }, (exception) => {
        // console.error(exception);
      //  alert("fgdfg")
        that.doAuthorizationApp();
        loading.dismiss();

      });
    } catch (e) {
      //alert("fgdfg")
      // alert(JSON.stringify(e))
      loading.dismiss();

      this.router.navigate(['login']);
    }
  }



  async doAuthorizationApp() {

    let that = this;
    const loading = await this.loadingController.create({
      animated: true,
      spinner: "bubbles",
      message: 'Loading...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    try {
      this.authService.authenticationState.subscribe(state => {

        if (state) {

          setTimeout(x => {

            try {
              this.storage.get(USER_DATA).then(user => {
                //  console.log("User Data Found "+user);
                if (user) {
                  // //  console.log("User Data Found ");
                  let myuser = JSON.parse(user);

                  let MyInitialRole_id = myuser.roles[0].roleid;
                  let userType = myuser.type;
                  console.log(" Employee Type:: " + userType);
                  if (MyInitialRole_id == 1) {
                    //  console.log("Role Found Employee :: "+myuser.roles[0].roleid);

                    if (userType == "EX-EMPLOYEE") {
                      this.router.navigate(['/ex-employee-dashboard', {replaceUrl: true , isStart: 1 }]);
                    } else {
                      this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                    }
                  } else if (MyInitialRole_id == 2) {
                    //  console.log("Role Found Director :: "+myuser.roles[0].roleid);  
                    this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                  } else if (MyInitialRole_id == 3) {
                    //  console.log("Role Found Incharge :: "+myuser.roles[0].roleid);
                    // this.router.navigate(['in-charge-dashboard']);
                    if (this.haveEmployeeRule(myuser.roles)) {
                      this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                    } else {
                      this.router.navigate(['/in-charge-dashboard']);
                    }
                  } else if (MyInitialRole_id == 4) {
                    this.router.navigate(['/care-taker-dash-board']);
                    //  console.log("Role Found Caretaker:: "+myuser.roles[0].roleid);
                    //
                  } else if (MyInitialRole_id == 5) {
                    //  console.log("Role Found Admin :: "+myuser.roles[0].roleid); 
                    this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                  } else if (MyInitialRole_id == 6) {
                    //  console.log("Role Found Functional Head :: "+myuser.roles[0].roleid);
                    this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                  } else if (MyInitialRole_id == 7) {
                    //  console.log("Role Found Managing Director :: "+myuser.roles[0].roleid);
                    this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                  } else if (MyInitialRole_id == 8) {
                    //  console.log("Role Found Functional Head HR:: "+myuser.roles[0].roleid);
                    this.router.navigate(['/employee-dash-board', { replaceUrl: true ,isStart: 1 }]);

                  } else if (MyInitialRole_id == 9) {
                    //  console.log("Role Found Location Head:: "+MyInitialRole_id);
                    this.router.navigate(['/employee-dash-board', {replaceUrl: true , isStart: 1 }]);

                  }
                } else {
                  //  console.log("No User Data Found##");
                 // that.router.navigate(['login']);
                  that.router.navigate(['/login'], { replaceUrl: true });
                }
              });

            } catch (Exce) {
              //  console.log("No User Data Found");
              //  console.log("Exce :: "+ JSON.stringify(Exce));

              that.router.navigate(['/login'], { replaceUrl: true });
             // that.router.navigate(['login']);
            } finally {
              loading.dismiss();
            }


            // loading.dismiss();
            //  this.router.navigate(['check-room-availability']);
          }, 200);

        } else {
          loading.dismiss();
          //  console.log("Auth State Fail");
        //  that.router.navigate(['login']);

          that.router.navigate(['/login'], { replaceUrl: true });
          //  this.router.navigate(['ex-employee-dashboard']);
          // this.router.navigate(['booking-partial-cancellation']);
          //  this.router.navigate(['check-room-availability']);
          // this.router.navigate(['incharge-management']);
        }
      }, (err) => {
        console.error(err);
        loading.dismiss();
       // this.router.navigate(['login']);

        that.router.navigate(['/login'], { replaceUrl: true });
      });
    } catch (err) {
      loading.dismiss();
     // this.router.navigate(['login']);

      that.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  backButtonEvent() {

    this.platform.backButton.subscribe(async () => {

      try {
        //  console.log("My Active11 "+this.router.url); 

        if (this.router.url === '/login') {
          navigator['app'].exitApp();
        } else if (this.router.url === 'employee-dash-board') {
          navigator['app'].exitApp();
        } else if (this.router.url === '/employee-dash-board') {
          navigator['app'].exitApp();
        } else if (this.router.url === '/care-taker-dash-board') {
          navigator['app'].exitApp();
        } else if (this.router.url === '/in-charge-dashboard') {
          navigator['app'].exitApp();
        } else {
          //  console.log("Url Not Match "); 
        }
      } catch (err) {
        //  console.log(err);
        navigator['app'].exitApp();
      }



    });
  }
  async presentToast() {
    try {
      const toast = await this.toastCtrl.create({
        message: 'Press back again to exit App.',
        duration: 2000
      });
      toast.present();
    } catch (err) {
      //  console.log(err);
    }

  }
  initializeDatabase() {
    try {
      this.dbTaskService.listNotification().then((data: any) => {
      }, (error) => {
        //  console.log(error);
      });
      this.dbTaskService.getUnreadNotification().then((data: any) => {
      }, (error) => {
      });
    } catch (error) {
      //  console.log(error);
    }

  }
  haveEmployeeRule(myRules) {
    let haveEmployeeRule = false;
    try {

      for (let rule of myRules) {

        if (rule.roleid == 1) {
          // ROLE_DIRECTOR
          haveEmployeeRule = true;
        } else if (rule.roleid == 2) {
          // ROLE_DIRECTOR
          haveEmployeeRule = true;
        } else if (rule.roleid == 3) {
          // ROLE_INCHARGE

        } else if (rule.roleid == 4) {
          // ROLE_CARETAKER
        } else if (rule.roleid == 5) {
          haveEmployeeRule = true;
          // ROLE_ADMIN
        } else if (rule.roleid == 6) {
          //ROLE_FUNCTIONALHEAD
          haveEmployeeRule = true;
        } else if (rule.roleid == 7) {
          //ROLE_MD
          haveEmployeeRule = true;
        } else if (rule.roleid == 8) {
          //ROLE_MD
          haveEmployeeRule = true;
        } else if (rule.roleid == 9) {
          //ROLE_MD
          haveEmployeeRule = true;
        }
        //  console.log(""+rule.roleid);
      }

    } catch (Exce) {
      //  console.log(Exce.toString());
    } finally {
      return haveEmployeeRule;
    }
  }

  checkForUpdates() {
    try {
      this.platform.ready().then(() => {
        this.clearCache(); // Clear cache on app start
        if (this.swUpdate.isEnabled) {
          this.swUpdate.checkForUpdate().then(() => {
            this.swUpdate.available.subscribe(event => {
              if (confirm('New version available. Load new version?')) {
                window.location.reload();
              }
            });
          });
        }
      });
    } catch (error) {

    }
  }

  clearCache() {
    try {


      if ('caches' in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
            console.log("Cache Cleared " + cacheName)
          });
        });
      }
    } catch (error) {

    }
  }

}
